@charset "UTF-8";
.card {
  border: none;
  box-shadow: 0 2px 10px #e0e0e0;
  background-color: #fff; }
  .card .card-header {
    background-color: unset; }

.btn-lg {
  padding: 1rem 1.5rem;
  font-size: 1.063rem;
  line-height: 1.5;
  border-radius: 0.3rem; }
  .btn-lg:hover {
    text-decoration: none; }

.object-fit-cover {
  object-fit: cover; }

.jumbotron {
  border: none;
  box-shadow: 0 2px 10px #e0e0e0;
  background-color: #fff; }

.btn, .btn-group-lg > .btn {
  border-radius: 0.25rem; }

.mt-5 {
  margin-top: 2.5rem !important; }

.mr-5 {
  margin-right: 2.5rem !important; }

.mb-5 {
  margin-bottom: 2.5rem !important; }

.ml-5 {
  margin-left: 2.5rem !important; }

@media screen and (max-width: 992px) {
  .m-mt-3 {
    margin-top: 1rem !important; } }

.border-bottom-cubiquement {
  border-bottom: 5px solid #30C133; }

.border-bottom-minecraft-fr {
  border-bottom: 5px solid #87BFBE; }

.border-bottom-omgserv {
  border-bottom: 5px solid #ee3623; }

.border-bottom-languagecraft {
  border-bottom: 5px solid #37753a; }

.transition-linear {
  transition: all 0.3s; }

hr {
  height: 1px; }

.rotate-180 {
  transform: rotate(180deg); }

.btn-imdb {
  background-color: #f3ce13;
  color: #000;
  font-weight: 700;
  font-family: "Impact";
  font-size: 1.7rem;
  height: 100%; }
  .btn-imdb:hover {
    background-color: #f5d844;
    color: #000; }

.btn-pmc {
  background-color: #2E100C;
  color: #000;
  font-weight: normal; }
  .btn-pmc:hover {
    background-color: #561e17;
    color: #000; }

.btn-twitter {
  background-color: #00aced;
  color: #fff; }
  .btn-twitter:hover {
    background-color: #21c2ff;
    color: #fff; }

.btn-facebook {
  background-color: #3b5998;
  color: #fff; }
  .btn-facebook:hover {
    background-color: #4c70ba;
    color: #fff; }

.btn-twitter span, .btn-twitter img, .btn-facebook span, .btn-facebook img, .btn-imdb span, .btn-imdb img, .btn-pmc span, .btn-pmc img {
  height: 2.5rem;
  display: flex;
  align-items: center; }

@media screen and (max-width: 768px) {
  .alert {
    display: block; } }

.btn-outline-teal {
  border-color: #00897b;
  background-color: transparent;
  background-image: none;
  color: #00897b; }

.btn-teal, .btn-outline-teal:hover {
  background-color: #00897b;
  border-color: #00897b;
  color: #fff; }

.btn-outline-red {
  border-color: #e53935;
  background-color: transparent;
  background-image: none;
  color: #e53935; }

.btn-red, .btn-outline-red:hover {
  background-color: #e53935;
  border-color: #e53935;
  color: #fff; }

.btn-outline-orange {
  border-color: #fb8c00;
  background-color: transparent;
  background-image: none;
  color: #fb8c00; }

.btn-orange, .btn-outline-orange:hover {
  background-color: #fb8c00;
  border-color: #fb8c00;
  color: #fff; }

.btn-outline-indigo {
  border-color: #3949ab;
  background-color: transparent;
  background-image: none;
  color: #3949ab; }

.btn-indigo, .btn-outline-indigo:hover {
  background-color: #3949ab;
  border-color: #3949ab;
  color: #fff; }

.btn-outline-yellow {
  border-color: #fdd835;
  background-color: transparent;
  background-image: none;
  color: #fdd835; }

.btn-yellow, .btn-outline-yellow:hover {
  background-color: #fdd835;
  border-color: #fdd835;
  color: #fff; }

.colored-border.left {
  border-left: 5px solid #fff; }

.colored-border.top {
  border-top: 5px solid #fff; }

.colored-border.bottom {
  border-bottom: 5px solid #fff; }

.colored-border.right {
  border-right: 5px solid #fff; }

.colored-border.indigo {
  border-color: #3949ab; }
  .colored-border.indigo svg {
    color: #3949ab; }

.colored-border.light-indigo {
  border-color: #9fa8da; }
  .colored-border.light-indigo svg {
    color: #9fa8da; }

.colored-border.yellow {
  border-color: #fdd835; }
  .colored-border.yellow svg {
    color: #fdd835; }

.colored-border.teal {
  border-color: #00897b; }
  .colored-border.teal svg {
    color: #00897b; }

.colored-border.red {
  border-color: #e53935; }
  .colored-border.red svg {
    color: #e53935; }

.colored-border.light-red {
  border-color: #ef9a9a; }
  .colored-border.light-red svg {
    color: #ef9a9a; }

.colored-border.blue {
  border-color: #1e88e5; }
  .colored-border.blue svg {
    color: #1e88e5; }

.colored-border.light-blue {
  border-color: #90caf9; }
  .colored-border.light-blue svg {
    color: #90caf9; }

.colored-border.orange {
  border-color: #fb8c00; }
  .colored-border.orange svg {
    color: #fb8c00; }

.colored-border.light-orange {
  border-color: #ffcc80; }
  .colored-border.light-orange svg {
    color: #ffcc80; }

.colored-border.grey {
  border-color: #757575; }
  .colored-border.grey svg {
    color: #757575; }

.colored-border.light-grey {
  border-color: #eeeeee; }
  .colored-border.light-grey svg {
    color: #eeeeee; }

.colored-border.purple {
  border-color: #8e24aa; }
  .colored-border.purple svg {
    color: #8e24aa; }

.colored-border.light-purple {
  border-color: #ce93d8; }
  .colored-border.light-purple svg {
    color: #ce93d8; }

.colored-border.green {
  border-color: #43a047; }
  .colored-border.green svg {
    color: #43a047; }

.colored-border.light-green {
  border-color: #a5d6a7; }
  .colored-border.light-green svg {
    color: #a5d6a7; }

.colored-border.brown {
  border-color: #6d4c41; }
  .colored-border.brown svg {
    color: #6d4c41; }

.colored-border.light-brown {
  border-color: #bcaaa4; }
  .colored-border.light-brown svg {
    color: #bcaaa4; }

.colored-border.light-deep-orange {
  border-color: #ffab91; }
  .colored-border.light-deep-orange svg {
    color: #ffab91; }

@media (min-width: 1200px) {
  .container.large-container {
    max-width: 1180px; } }

@media (min-width: 1400px) {
  .container.large-container {
    max-width: 1350px; } }

@media screen and (max-width: 992px) {
  .only-desktop {
    display: none !important; } }

.twitter:hover svg, .twitter.colored svg {
  color: #1da1f2; }

.artstation:hover svg, .artstation.colored svg {
  color: #13aff0; }

.facebook:hover svg, .facebook.colored svg {
  color: #29487d; }

.discord:hover svg, .discord.colored svg {
  color: #7289da; }

.youtube:hover svg, .youtube.colored svg {
  color: #ff0000; }

.vimeo:hover svg, .vimeo.colored svg {
  color: #0faef1; }

.instagram:hover svg, .instagram.colored svg {
  color: #cd486b; }

.deviantart:hover svg, .deviantart.colored svg {
  color: #00e59b; }

.website:hover svg, .website.colored svg {
  color: #006699; }

.linkedin:hover svg, .linkedin.colored svg {
  color: #0073b1; }

.soundcloud:hover svg, .soundcloud.colored svg {
  color: #f50; }

.behance:hover svg, .behance.colored svg {
  color: #0057ff; }

.imdb:hover svg, .imdb.colored svg {
  color: #f3ce13; }

.tumblr:hover svg, .tumblr.colored svg {
  color: #35465c; }

.colored:hover {
  opacity: 0.9; }

@keyframes snooze {
  0% {
    transform: rotate(0deg); }
  8% {
    transform: rotate(2deg); }
  16% {
    transform: rotate(-2deg); }
  24% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(0deg); } }

@media screen and (min-width: 992px) {
  .animation-snooze {
    animation: snooze 4s infinite;
    transform-origin: 50% 50% 0; } }

footer.footer {
  background-color: #21242b;
  padding: 3rem;
  bottom: 0;
  width: 100%;
  position: absolute; }
  footer.footer a {
    margin: 0 1rem;
    letter-spacing: 0.1rem;
    color: #fff; }
    footer.footer a svg {
      font-size: 1.5rem; }
    footer.footer a:hover {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none; }
    footer.footer a:first-child {
      margin-top: 0;
      margin-left: 0; }
    footer.footer a:last-child {
      margin-top: 0;
      margin-right: 0; }
  footer.footer p {
    margin: 0 1rem;
    letter-spacing: 0.1rem;
    color: #fff; }
    footer.footer p:first-child {
      margin-left: 0; }

@media screen and (max-width: 992px) {
  footer.footer span.rs a {
    margin: 0 0.6rem; } }

@media screen and (max-width: 768px) {
  footer.footer .d-flex {
    flex-direction: column !important;
    align-items: center; }
  footer.footer span {
    display: flex;
    flex-direction: column; }
  footer.footer span.rs {
    display: inline-block; }
  footer.footer a, footer.footer span {
    margin: 0 0.5rem !important;
    margin-top: 1rem !important;
    text-align: center; }
  footer.footer p {
    text-align: center; } }

@media screen and (max-width: 576px) {
  footer.footer > div > div > span:first-child {
    display: flex;
    flex-direction: column;
    align-items: center; } }

nav.navbar {
  border: none;
  padding: 1.5rem 12.7rem;
  min-height: 100px;
  box-shadow: 0 0 4px #9e9e9e; }
  nav.navbar .nav-link {
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    cursor: pointer; }
  nav.navbar .navbar-brand img {
    height: 50px; }
  nav.navbar .navbar-light .navbar-nav span.nav-link:hover, nav.navbar .navbar-light .navbar-nav span.nav-link:focus, nav.navbar .navbar-light .navbar-nav span.nav-link:active {
    color: rgba(0, 0, 0, 0.5); }
  nav.navbar li.nav-item {
    margin-left: 0.8rem; }
  nav.navbar .dropdown:hover > .dropdown-menu, nav.navbar .dropdown:focus > .dropdown-menu {
    transform: rotateX(0deg); }
  nav.navbar .dropdown-menu {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 2, 0.1);
    transform: rotateX(-90deg);
    transform-origin: top;
    transition: transform 0.2s;
    display: block; }
    nav.navbar .dropdown-menu .dropdown-item {
      font-size: 1.1rem;
      border-bottom: 1px solid #F3F3F3;
      padding: 0.7rem 1rem; }
      nav.navbar .dropdown-menu .dropdown-item:last-child {
        border-bottom: none; }
      nav.navbar .dropdown-menu .dropdown-item:focus {
        outline: none;
        background-color: #f8f9fa;
        color: #343a40; }

@media screen and (max-width: 1400px) {
  nav.navbar {
    padding: 1.5rem 1rem; } }

@media screen and (max-width: 1200px) {
  nav.navbar {
    padding: 1.5rem 1rem; } }

@media screen and (min-width: 992px) {
  nav.navbar {
    min-height: 110px; } }

@media screen and (max-width: 992px) {
  nav.navbar.navbar-light .navbar-brand img {
    height: 35px; }
  nav.navbar.navbar-light .navbar-nav li.nav-item {
    margin-left: 0;
    border-top: 1px solid #E0E0E0; }
    nav.navbar.navbar-light .navbar-nav li.nav-item span.nav-link {
      color: rgba(0, 0, 0, 0.3);
      font-size: 0.8rem;
      text-align: center; }
  nav.navbar.navbar-light .dropdown-menu, nav.navbar.navbar-light .show .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    display: block;
    transform: rotateX(0deg); }
    nav.navbar.navbar-light .dropdown-menu .dropdown-item, nav.navbar.navbar-light .show .dropdown-menu .dropdown-item {
      font-size: 1.1rem;
      margin: 0;
      padding: 0.5rem 0;
      border: none;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase; } }

@media screen and (max-width: 576px) {
  .navbar .navbar-brand img {
    height: 25px; } }

.head {
  background: #adb5bd;
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem; }
  .head.with-neg-margin {
    margin-bottom: -6rem; }
  .head.project img {
    height: 800px; }
  .head.project-module img {
    height: 700px; }
  .head.project-module .cover-message h2 {
    font-size: 3rem;
    font-weight: normal; }
  .head img {
    width: 100%;
    z-index: 0;
    position: relative;
    height: 300px;
    object-fit: cover; }
  .head .cover-message {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px; }
    .head .cover-message h1, .head .cover-message h2 {
      text-shadow: 1px 1px 4px #343a40;
      text-align: center;
      font-size: 3.6rem;
      font-weight: bold !important; }

@media screen and (max-width: 992px) {
  .head.with-neg-margin {
    margin-bottom: -4rem; } }

@media screen and (max-width: 576px) {
  .cover-message h1 {
    color: #f8f9fa !important;
    text-shadow: 0 1px 4px #adb5bd;
    font-size: 1.7rem; }
  .head.with-neg-margin {
    margin-bottom: 0; } }

.cookie-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999;
  padding: 4rem 0;
  box-shadow: 0 0 4px #9e9e9e; }
  .cookie-nav > div {
    display: flex;
    align-items: center; }

.cookie-text {
  flex: 1; }

.cookie-buttons .btn {
  margin-left: 1rem; }
  .cookie-buttons .btn.btn-secondary {
    opacity: 0.7; }

.gm-fullscreen-control, .gmnoprint {
  display: none; }

.google-marker {
  position: absolute;
  transform: translate(-50%, -100%); }
  .google-marker svg {
    font-size: 1.8rem;
    color: #e53935; }
  .google-marker img {
    height: 40px; }

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px; }
  .play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent white; }
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.9); }

.image-gallery-slide .image-gallery-description {
  right: 0 !important;
  left: 0 !important;
  bottom: 0;
  padding: 2rem 1rem;
  background: rgba(0, 0, 0, 0.7); }

.image-gallery-thumbnails {
  padding: 1rem 0; }
  .image-gallery-thumbnails .image-gallery-thumbnail img {
    transition: width 0.3s; }
  .image-gallery-thumbnails .image-gallery-thumbnail.active {
    border: 0px solid #fff; }

.image-gallery-slide {
  background-color: #e0e0e0; }

.image-gallery-right-nav:before {
  transform: rotate(180deg); }

.image-gallery-right-nav, .image-gallery-left-nav {
  outline: unset;
  opacity: 0.6;
  transition: all 0.3s; }
  .image-gallery-right-nav:before, .image-gallery-left-nav:before {
    content: '';
    width: 70px;
    height: 60px;
    background: transparent url("../images/icons/chevron-left-solid.svg") no-repeat;
    background-size: 40px;
    background-position: center; }
  .image-gallery-right-nav:focus, .image-gallery-left-nav:focus {
    outline: unset; }
  .image-gallery-right-nav:hover, .image-gallery-left-nav:hover {
    opacity: 1; }
    .image-gallery-right-nav:hover:before, .image-gallery-left-nav:hover:before {
      color: #fff; }

html {
  position: relative;
  min-height: 100%; }
  html.wf-active body, html.wf-active p {
    font-family: 'roboto', sans-serif; }
  html.wf-active nav.navbar, html.wf-active .cover-message h1, html.wf-active .cover-message h2 {
    font-family: 'HeitiTC', roboto;
    font-weight: 300; }
  html body {
    margin-top: 110px;
    margin-bottom: 167.2px;
    background-color: #eeeeee; }
    html body .band-inline {
      margin: 2.5rem -15px; }
      html body .band-inline div {
        display: flex;
        align-items: center;
        margin-bottom: 1rem; }
        html body .band-inline div p {
          text-transform: uppercase;
          font-weight: bold;
          margin: 0;
          text-align: center;
          width: 100%; }
        html body .band-inline div .btn {
          width: 100%; }
    html body .jumbotron.error {
      background-color: #fff;
      border-left: 5px solid #e57373; }
    html body > div {
      min-height: inherit; }
    html body .point-before::before {
      content: '•';
      margin: 0 0.75rem; }

.switch-wrapper {
  position: relative;
  width: 100%; }

.switch-wrapper > div {
  position: absolute;
  width: 100%; }

.intervention {
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px #e0e0e0;
  display: flex;
  transition: all 0.2s;
  color: #212121;
  height: auto;
  position: relative;
  padding-left: 130px;
  align-items: center;
  height: 12rem; }
  .intervention img {
    width: 130px;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    transition: all 0.2s; }
  .intervention .description {
    color: #9e9e9e; }
  .intervention:hover {
    background-color: #757575;
    cursor: pointer;
    text-decoration: none;
    color: #fff; }
    .intervention:hover img {
      opacity: 1; }
    .intervention:hover .description {
      color: #f5f5f5; }

.intervention:last-child {
  margin-bottom: 0; }

@media screen and (max-width: 992px) {
  .intervention img {
    opacity: 1; }
  .intervention {
    height: auto; } }

#back-to-top {
  background: #424242;
  z-index: 3; }

.red-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ef5350;
  border-radius: 50%;
  margin-right: 0.25rem; }

.teal-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #26a69a;
  border-radius: 50%;
  margin-right: 0.25rem; }

.indigo-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #5c6bc0;
  border-radius: 50%;
  margin-right: 0.25rem; }

.orange-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fb8c00;
  border-radius: 50%;
  margin-right: 0.25rem; }

.poster {
  margin: 1rem 0; }
  .poster > div {
    box-shadow: 0 2px 4px #e0e0e0;
    background-color: #fff;
    border-radius: 0.25rem;
    overflow: hidden; }
  .poster a {
    transition: all 0.3s;
    background-color: #bdbdbd;
    height: 100%;
    width: 100%;
    min-height: 350px;
    overflow: hidden; }
    .poster a img {
      height: 100%;
      transition: all 0.3s;
      width: 100%;
      object-fit: cover;
      min-height: 350px; }
    .poster a > span {
      top: 0;
      opacity: 0;
      transition: all 0.2s;
      background-color: rgba(0, 0, 0, 0.7);
      overflow: hidden;
      width: 100%;
      padding: 1rem 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%; }
      .poster a > span > span {
        color: #f8f9fa;
        display: block;
        width: 100%;
        text-align: justify;
        transition: all 0.2s;
        padding: 30px; }
    .poster a:hover span {
      opacity: 1; }
  .poster p {
    color: #343a40;
    transition: all 0.2s; }

.workshop .card-body {
  display: flex;
  flex-direction: column; }
  .workshop .card-body > div {
    flex: 1; }
    .workshop .card-body > div .feature {
      display: flex;
      align-items: center;
      border-top: 5px solid #fff; }
      .workshop .card-body > div .feature svg {
        margin-right: 1.5rem;
        width: 1.5rem;
        text-align: center; }

.gallery {
  overflow: hidden; }
  .gallery .thumbnail {
    box-sizing: border-box;
    display: block;
    float: left;
    line-height: 0;
    padding-right: 1rem;
    padding-bottom: 1rem;
    overflow: hidden; }
    .gallery .thumbnail img {
      border: 0;
      display: block;
      height: 200px;
      max-width: 100%;
      width: auto; }

@media screen and (max-width: 992px) {
  .gallery .thumbnail img {
    height: 150px; }
  .gallery > div:first-child {
    margin-top: 1rem; }
  .m-order-1 {
    order: 1; }
  .m-order-2 {
    order: 2; }
  .buttons {
    flex-direction: column; }
    .buttons .btn {
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 1rem; } }

@media screen and (max-width: 768px) {
  .gallery .thumbnail {
    padding-right: 0;
    width: 100%; }
    .gallery .thumbnail img {
      height: auto;
      width: 100%; } }

.presentation-module {
  background-color: #fff;
  background: transparent url("../images/home/background-presentation.jpg") no-repeat;
  background-position: top;
  background-size: cover; }
  .presentation-module .presentation {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4rem 0 2rem 0; }
    .presentation-module .presentation h3 {
      color: #fafafa;
      text-shadow: 0px 2px 3px #424242; }
    .presentation-module .presentation .jumbotron {
      box-shadow: 0px 2px 3px #424242; }
  .presentation-module .button-links {
    background-color: #eeeeee;
    padding: 3rem 0; }
    .presentation-module .button-links .band-inline {
      margin: 0 -15px; }
      .presentation-module .button-links .band-inline div {
        margin-bottom: 0; }

.recruitment-module {
  background-color: #fff;
  background: transparent url("../images/home/background-recruitment.jpg") no-repeat;
  background-position: top;
  background-size: cover;
  margin-bottom: -1.55rem;
  position: relative;
  border-top: 15px solid #21242b; }
  .recruitment-module .recruitment {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 9rem 0 9rem 0; }
    .recruitment-module .recruitment h3 {
      color: #fafafa;
      text-shadow: 0px 2px 3px #424242; }
  .recruitment-module .button-links {
    background-color: #e0e0e0;
    padding: 1rem 0;
    box-shadow: 0px -1px 2px #757575; }
    .recruitment-module .button-links .band-inline {
      margin: 0 -15px; }
      .recruitment-module .button-links .band-inline div {
        margin-bottom: 0; }

.video-module {
  position: relative;
  margin-bottom: 5rem; }
  .video-module .overlay-delimiter {
    height: 500px;
    overflow: hidden;
    position: relative; }
    .video-module .overlay-delimiter .overlay {
      position: absolute;
      height: 100%;
      left: 0;
      right: 0; }
      .video-module .overlay-delimiter .overlay .embed-responsive {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
  .video-module .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .video-module .content h1, .video-module .content .h1 {
      color: #FFF;
      text-shadow: 1px 1px 3px #212121; }
  .video-module .banner {
    background: #f5f5f5;
    height: 550px;
    width: 70%;
    min-width: 280px;
    box-shadow: 0px 2px 3px #bdbdbd;
    border-bottom-left-radius: 20px 8px;
    border-bottom-right-radius: 20px 8px;
    overflow: hidden;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 3.5rem; }
    .video-module .banner .contact {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #eeeeee;
      height: 4rem;
      padding-top: 10px;
      transition: background-color 0.2s; }
      .video-module .banner .contact:hover {
        background-color: #e0e0e0; }
      .video-module .banner .contact:before {
        content: '';
        position: absolute;
        background-color: #f5f5f5;
        left: 0;
        top: 0;
        width: 100%;
        height: 10px;
        border-bottom-left-radius: 20px 8px;
        border-bottom-right-radius: 20px 8px; }
      .video-module .banner .contact a {
        color: #343a40;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center; }
        .video-module .banner .contact a:hover {
          text-decoration: none; }
    .video-module .banner .about-video {
      flex: 1; }
    .video-module .banner .social-networks {
      margin-bottom: 1rem; }
      .video-module .banner .social-networks .rs {
        display: flex;
        justify-content: space-between;
        width: 180px;
        margin: auto; }
        .video-module .banner .social-networks .rs a {
          color: #343a40;
          font-size: 2rem; }

@media screen and (max-width: 992px) {
  html body {
    margin-top: 100px; }
  .video-module {
    margin-bottom: 2rem; }
    .video-module .overlay-delimiter {
      height: auto; }
      .video-module .overlay-delimiter .overlay {
        top: 0;
        position: relative; }
    .video-module .banner {
      width: 100%;
      height: auto;
      box-shadow: none; }
      .video-module .banner .h2 {
        display: none; }
      .video-module .banner .mb-4 {
        margin-bottom: 0 !important; }
      .video-module .banner .about-video p {
        text-align: center !important; }
    .video-module .content {
      position: relative;
      background-color: #f5f5f5;
      box-shadow: 0px 2px 3px #bdbdbd; }
      .video-module .content .h1 {
        font-size: 2rem;
        padding: 1rem 0;
        margin-bottom: 0;
        color: #343a40;
        text-shadow: 1px 1px 3px #eeeeee;
        text-align: center; }
      .video-module .content .h-100 {
        height: auto !important; } }

@media screen and (max-width: 768px) {
  html body {
    margin-bottom: 313px; }
    html body .grey-background {
      position: relative;
      margin-bottom: -2.5rem; }
  .video-module .overlay-delimiter {
    display: none; } }

@media screen and (max-width: 576px) {
  html body {
    margin-bottom: 367px !important; }
  .poster a span {
    opacity: 1; }
    .poster a span span {
      font-size: 0.9rem; } }

@media screen and (max-width: 768px) {
  .newCaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.scroll {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 0.25rem;
  position: relative; }
  .scroll .scroller-bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(transparent, #eeeeee);
    height: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end; }

@media screen and (max-width: 992px) {
  .scroll {
    height: auto;
    overflow-y: visible; }
    .scroll .scroller-bottom {
      display: none; } }

.project-module .projects-link {
  padding: 3rem 0;
  background-color: #e0e0e0; }

.project-module .jumbotron {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0; }

.project-module .project .jumbotron {
  border-right: 5px solid grey;
  position: relative;
  overflow: hidden; }
  .project-module .project .jumbotron::before {
    content: '';
    height: 100%;
    margin: -2rem 0;
    width: 30%;
    position: absolute;
    transition: width 0.4s;
    left: 0; }
  .project-module .project .jumbotron:hover::before {
    width: 60%; }
  .project-module .project .jumbotron.starcube_galaxy {
    border-color: #1C4166; }
    .project-module .project .jumbotron.starcube_galaxy .btn {
      background-color: #1C4166;
      border-color: #1C4166; }
      .project-module .project .jumbotron.starcube_galaxy .btn:hover {
        background-color: #275b8e;
        border-color: #275b8e; }
    .project-module .project .jumbotron.starcube_galaxy::before {
      background-image: linear-gradient(to right, #1C4166, #fff); }
  .project-module .project .jumbotron.amel_et_aman {
    border-color: #D7CE86; }
    .project-module .project .jumbotron.amel_et_aman .h6 {
      color: #D7CE86;
      font-weight: 400; }
    .project-module .project .jumbotron.amel_et_aman .btn {
      background-color: #D7CE86;
      border-color: #D7CE86;
      color: #000; }
      .project-module .project .jumbotron.amel_et_aman .btn:hover {
        background-color: #e4deac;
        border-color: #e4deac; }
    .project-module .project .jumbotron.amel_et_aman::before {
      background-image: linear-gradient(to right, #D7CE86, #fff); }
  .project-module .project .jumbotron.noctis {
    border-color: #3D715A; }
    .project-module .project .jumbotron.noctis .btn {
      background-color: #3D715A;
      border-color: #3D715A; }
      .project-module .project .jumbotron.noctis .btn:hover {
        background-color: #4f9274;
        border-color: #4f9274; }
    .project-module .project .jumbotron.noctis::before {
      background-image: linear-gradient(to right, #3D715A, #fff); }
  .project-module .project .jumbotron.edge {
    border-color: #B68456; }
    .project-module .project .jumbotron.edge .btn {
      background-color: #B68456;
      border-color: #B68456; }
      .project-module .project .jumbotron.edge .btn:hover {
        background-color: #c59e7a;
        border-color: #c59e7a; }
    .project-module .project .jumbotron.edge::before {
      background-image: linear-gradient(to right, #B68456, #fff); }
  .project-module .project .jumbotron.transcendance {
    border-color: #7F7F67; }
    .project-module .project .jumbotron.transcendance .h6 {
      color: #7F7F67;
      font-weight: 400; }
    .project-module .project .jumbotron.transcendance .btn {
      background-color: #7F7F67;
      border-color: #7F7F67; }
      .project-module .project .jumbotron.transcendance .btn:hover {
        background-color: #989881;
        border-color: #989881; }
    .project-module .project .jumbotron.transcendance::before {
      background-image: linear-gradient(to right, #7F7F67, #fff); }
  .project-module .project .jumbotron.cinq-cent-cinq {
    border-color: #6270a0; }
    .project-module .project .jumbotron.cinq-cent-cinq .h6 {
      color: #6270a0;
      font-weight: 400; }
    .project-module .project .jumbotron.cinq-cent-cinq .btn {
      background-color: #6270a0;
      border-color: #6270a0; }
      .project-module .project .jumbotron.cinq-cent-cinq .btn:hover {
        background-color: #828db3;
        border-color: #828db3; }
    .project-module .project .jumbotron.cinq-cent-cinq::before {
      background-image: linear-gradient(to right, #6270a0, #fff); }
  .project-module .project .jumbotron.nuit_chez_thompsons {
    border-color: #794238; }
    .project-module .project .jumbotron.nuit_chez_thompsons .h6 {
      color: #794238;
      font-weight: 400; }
    .project-module .project .jumbotron.nuit_chez_thompsons .btn {
      background-color: #794238;
      border-color: #794238; }
      .project-module .project .jumbotron.nuit_chez_thompsons .btn:hover {
        background-color: #9c5548;
        border-color: #9c5548; }
    .project-module .project .jumbotron.nuit_chez_thompsons::before {
      background-image: linear-gradient(to right, #794238, #fff); }
  .project-module .project .jumbotron.arcadia {
    border-color: #09624B; }
    .project-module .project .jumbotron.arcadia .h6 {
      color: #09624B;
      font-weight: 400; }
    .project-module .project .jumbotron.arcadia .btn {
      background-color: #09624B;
      border-color: #09624B; }
      .project-module .project .jumbotron.arcadia .btn:hover {
        background-color: #0d916f;
        border-color: #0d916f; }
    .project-module .project .jumbotron.arcadia::before {
      background-image: linear-gradient(to right, #09624B, #fff); }
  .project-module .project .jumbotron.the_machimania_show {
    border-color: #AC1011; }
    .project-module .project .jumbotron.the_machimania_show .h6 {
      color: #AC1011;
      font-weight: 400; }
    .project-module .project .jumbotron.the_machimania_show .btn {
      background-color: #AC1011;
      border-color: #AC1011; }
      .project-module .project .jumbotron.the_machimania_show .btn:hover {
        background-color: #db1416;
        border-color: #db1416; }
    .project-module .project .jumbotron.the_machimania_show::before {
      background-image: linear-gradient(to right, #AC1011, #fff); }
  .project-module .project .jumbotron.le_sentier_des_souvenirs {
    border-color: #445E7D; }
    .project-module .project .jumbotron.le_sentier_des_souvenirs .h6 {
      color: #445E7D;
      font-weight: 400; }
    .project-module .project .jumbotron.le_sentier_des_souvenirs .btn {
      background-color: #445E7D;
      border-color: #445E7D; }
      .project-module .project .jumbotron.le_sentier_des_souvenirs .btn:hover {
        background-color: #56779e;
        border-color: #56779e; }
    .project-module .project .jumbotron.le_sentier_des_souvenirs::before {
      background-image: linear-gradient(to right, #445E7D, #fff); }
  .project-module .project .jumbotron.le_jour_des_moissons {
    border-color: #122a3d; }
    .project-module .project .jumbotron.le_jour_des_moissons .h6 {
      color: #122a3d;
      font-weight: 400; }
    .project-module .project .jumbotron.le_jour_des_moissons .btn {
      background-color: #122a3d;
      border-color: #122a3d; }
      .project-module .project .jumbotron.le_jour_des_moissons .btn:hover {
        background-color: #1e4564;
        border-color: #1e4564; }
    .project-module .project .jumbotron.le_jour_des_moissons::before {
      background-image: linear-gradient(to right, #122a3d, #fff); }

.project-module .project.col-lg-6 .jumbotron::before {
  width: 60%; }

.project-module .project.col-lg-6 .jumbotron:hover::before {
  width: 90%; }

.project-poster {
  height: 470px; }
  .project-poster.small-height {
    height: 400px; }
  .project-poster .poster-dots {
    position: absolute;
    bottom: 0;
    left: 0; }
  .project-poster > div > div {
    box-shadow: 0 2px 10px #e0e0e0;
    background-color: #fff;
    border-radius: 0.25em;
    position: relative; }
  .project-poster a {
    transition: all 0.3s;
    background-color: #e0e0e0;
    height: 350px;
    width: 236.6px; }
    .project-poster a img {
      height: 100%;
      transition: all 0.3s;
      border-top-left-radius: 0.25em;
      border-top-right-radius: 0.25em; }
    .project-poster a > span {
      top: 0;
      opacity: 0;
      transition: all 0.2s;
      background-color: rgba(0, 0, 0, 0.7);
      overflow: hidden;
      width: 100%;
      padding: 1rem 0;
      border-top-left-radius: 0.25em;
      border-top-right-radius: 0.25em; }
      .project-poster a > span > span {
        color: #f8f9fa;
        display: block;
        width: 230px;
        margin: auto;
        text-align: center;
        transition: all 0.2s; }
    .project-poster a:hover {
      margin-top: -8%;
      height: 400px;
      width: 270.4px; }
      .project-poster a:hover img {
        height: 100%; }
      .project-poster a:hover span {
        opacity: 1; }
  .project-poster p {
    color: #343a40;
    transition: all 0.2s; }

.btn-home {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; }

.my-shuffle-container {
  overflow: visible !important; }

.summary {
  position: fixed;
  right: 0;
  z-index: 2;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center; }
  .summary .summary-content {
    width: 40px;
    background-color: transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-right: 20px; }
    .summary .summary-content:hover {
      width: 100%;
      padding: 20px;
      background-color: rgba(238, 238, 238, 0.9); }
      .summary .summary-content:hover span {
        display: block; }
    .summary .summary-content span {
      color: #212121;
      display: none;
      width: 100%;
      position: relative;
      cursor: pointer;
      transition: margin 0.2s, color 0.2s;
      padding-right: 20px; }
      .summary .summary-content span:hover {
        color: #303f9f;
        text-decoration: none; }
    .summary .summary-content li {
      display: flex;
      position: relative;
      min-height: 25px; }
      .summary .summary-content li i:after, .summary .summary-content li svg:after {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #303f9f;
        position: absolute;
        border-radius: 50%;
        top: calc(50% - 3px);
        right: 0;
        transition: all 0.2s; }
      .summary .summary-content li:hover i:after, .summary .summary-content li:hover svg:after {
        right: -3px; }
  .summary ul {
    padding: 0;
    list-style-type: none;
    margin: 0; }
    .summary ul li {
      margin: 0;
      text-align: right; }

.grey-background .jumbotron.jumb-error {
  margin-top: 2rem;
  border-left: 5px solid #d9534f; }

.card-link {
  background-color: #c5cae9;
  border-radius: 0.25rem;
  color: #3f51b5;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  transition: all 0.2s;
  flex-direction: column;
  box-shadow: 0 2px 10px #e0e0e0; }
  .card-link button {
    background-color: #3f51b5;
    color: #FFF; }
    .card-link button:hover {
      background-color: #5c6bc0; }

.badge-secondary.badge-member {
  background-color: #9fa8da;
  transition: background-color 0.2s; }
  .badge-secondary.badge-member:hover {
    background-color: #7986cb; }

.badge-secondary.badge-ca {
  background-color: #5c6bc0; }

.badge-secondary.badge-dir {
  background-color: #7986cb; }

a[href="/recrutement"]:hover {
  text-decoration: none; }

.card .rs a {
  color: inherit; }
  .card .rs a:first-child {
    margin: 0 !important; }

.card.external-actor .rs {
  display: block; }
  .card.external-actor .rs::before {
    content: unset;
    margin: 0; }

.card .card-title {
  font-size: 1.25rem; }

.card .badge {
  font-size: 70%; }

@media screen and (max-width: 1400px) {
  .card .card-title {
    font-size: 1.04rem; }
  .card .badge {
    font-size: 60%; } }

@media screen and (max-width: 1200px) {
  .card .card-title {
    font-size: 1.1rem; }
  .card .badge {
    font-size: 70%; } }

@media screen and (max-width: 992px) {
  .card .card-title {
    font-size: 1.2rem; }
  .card .badge {
    font-size: 80%; }
  .project-module .project .jumbotron::before {
    margin: -2rem 0 !important; }
  .reward {
    width: 100% !important; }
  .presentation-module .button-links .band-inline div {
    margin-bottom: 1rem; }
  .jumbotron div + div > img {
    margin-top: 1rem; } }

@media screen and (max-width: 768px) {
  .summary {
    display: none; }
  .card .card-title {
    font-size: 1rem; }
  .card .badge {
    font-size: 60%; } }

@media screen and (max-width: 576px) {
  .card .card-title {
    font-size: 1.2rem; }
  .card .badge {
    font-size: 80%; } }

.bg-transparent {
  padding: 2rem;
  background: #eeeeee url("../images/backgrounds/transp_bg.png") repeat;
  display: flex; }
  .bg-transparent img {
    max-height: 100px;
    max-width: 100%; }

.article-element-blank p {
  padding: 0;
  margin: 0; }

.article-element-blank .jumbotron {
  padding: 1rem;
  margin-bottom: 0; }

.color-grey {
  color: #757575; }

.reward {
  padding: 0 3.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: calc(100% / 3 - 2rem);
  min-width: 350px;
  margin: 1rem; }
  .reward:before, .reward:after {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    background-size: auto 100%;
    opacity: 0.1; }
  .reward:before {
    left: 0;
    background: transparent url(../images/icons/laurel-left.svg) no-repeat;
    background-position: left; }
  .reward:after {
    right: 0;
    background: transparent url(../images/icons/laurel-right.svg) no-repeat;
    background-position: right; }
  .reward:hover:before, .reward:hover:after, .reward:hover .name .city:before {
    opacity: 0.4; }
  .reward:hover .name .city:before {
    left: calc(50% - 25px);
    width: 50px; }
  .reward .content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .reward p, .reward p span {
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 0px 50px #fff;
    font-size: 85%; }
  .reward .name {
    margin-bottom: 0;
    letter-spacing: 0.1rem; }
    .reward .name span {
      display: block; }
    .reward .name .city {
      font-size: 1rem;
      position: relative;
      margin-top: 0.5rem;
      padding-top: 0.5rem; }
      .reward .name .city:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - 10px);
        width: 20px;
        height: 1px;
        border-top: 1px solid #000;
        transition: all 0.3s;
        opacity: 0.2; }
  .reward .date {
    font-size: 0.9rem;
    font-weight: bold;
    color: #424242; }
  .reward .selection span {
    font-weight: bold; }
  .reward .more {
    font-size: 0.8rem; }

.logo-wrapper {
  text-align: center; }

.logo-partner {
  width: 300px;
  margin: auto; }
